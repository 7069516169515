import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import lodashGet from 'lodash/get';
import lodashOmit from 'lodash/omit';
import { Provider } from 'mobx-react';
import AppProvider from 'src/components/app-provider';
import PdfOutput from 'src/components/reform/pdf-output';
import PSPDFKit from 'src/utils/pspdfkit';
import CheckboxZone from './checkbox-zone';
import {
  ALL_WIDGETS,
  PDF_ANNOTATIONS,
  PDF_ANNOTATION_FILL_OVERLAY_ID,
  PDF_ANNOTATION_ROOT_CLASS,
  SIGNATURE_TABS,
  SPECIAL_FIELDS,
  TEXT_FIELD_FONT_SIZE,
  TEXT_RENDER_TYPES,
  TEXT_SPECIAL_FIELDS,
  ZONES,
} from './constants';
import DateTab from './date-tab';
import DateZone from './date-zone';
import FilestackImage from './image';
import InitialsTab from './initials-tab';
import InitialsZone from './initials-zone';
import PdfCheckbox from './pdf-checkbox';
import PdfRedactArea from './pdf-redact-area';
import PdfRedactText from './pdf-redact-text';
import PdfStamp from './pdf-stamp';
import PdfStrikeoutLine from './pdf-strikeout-line';
import PdfStrikeoutText from './pdf-strikeout-text';
import PdfText from './pdf-text';
import SectionZone from './section-zone';
import SignatureTab from './signature-tab';
import SignatureZone from './signature-zone';
import Text from './text';
import TextZone from './text-zone';
import Toggle from './toggle';
import UnknownAnnotation from './unknown-annotation';

export const createRootNode = (
  annotation,
  { className = '', designMode = false } = {}
) => {
  const node = document.createElement('div');
  node.className = classNames(PDF_ANNOTATION_ROOT_CLASS, className, {
    [`${PDF_ANNOTATION_ROOT_CLASS}--disabled`]:
      !designMode && Object.keys(ZONES).includes(annotation.customData?.type),
  });

  if (annotation.id) {
    node.id = `pdf-annotation-${annotation.id}`;
  }

  // Mainly for testing purposes (for now)
  const formFieldName = annotation?.customData?.formFieldName ?? false;
  const exportValue = annotation?.customData?.exportValue ?? false;
  if (formFieldName) {
    node.setAttribute('data-form-field-name', formFieldName);
  }
  if (formFieldName && exportValue !== false) {
    node.setAttribute('data-export-value', exportValue);
  }

  return node;
};

// The component must be wrapped under provider with ui and features store!
export const renderReactComponent = (component, parent, force = false) => {
  setTimeout(() => {
    if (parent.innerHTML === '' || force) {
      ReactDOM.render(component, parent);
    }
  }, 0);
};

export const createUnknownComponent = (annotation, store) => {
  return <UnknownAnnotation annotation={annotation} annotations={store} />;
};

// create a DOM node for signature tab fields
export const createTabComponent = (annotation, store, ghost = false) => {
  const { customData } = annotation;
  let component = null;

  switch (true) {
    case customData.type === 'signature-tab':
      component = (
        <SignatureTab
          annotation={annotation}
          annotations={store}
          ghost={ghost}
        />
      );
      break;

    case customData.type === 'initials-tab':
      component = (
        <InitialsTab
          annotation={annotation}
          annotations={store}
          ghost={ghost}
        />
      );
      break;
    case customData.type === 'date-tab':
      component = (
        <DateTab annotation={annotation} annotations={store} ghost={ghost} />
      );
      break;
    default:
      throw new Error('Unknown tab type');
  }

  return component;
};

// create a DOM node for signature tab fields
export const createZoneComponent = (annotation, store, ghost = false) => {
  const { customData } = annotation;
  let component = null;

  switch (true) {
    case customData.type === 'signature-zone':
      component = (
        <SignatureZone
          annotation={annotation}
          annotations={store}
          ghost={ghost}
        />
      );
      break;

    case customData.type === 'initials-zone':
      component = (
        <InitialsZone
          annotation={annotation}
          annotations={store}
          ghost={ghost}
        />
      );
      break;
    case customData.type === 'date-zone':
      component = (
        <DateZone annotation={annotation} annotations={store} ghost={ghost} />
      );
      break;
    case customData.type === 'checkbox-zone':
      component = (
        <CheckboxZone
          annotation={annotation}
          annotations={store}
          ghost={ghost}
        />
      );
      break;
    case customData.type === 'text-zone':
      component = (
        <TextZone annotation={annotation} annotations={store} ghost={ghost} />
      );
      break;
    case customData.type === 'section-zone':
      component = (
        <SectionZone
          annotation={annotation}
          annotations={store}
          ghost={ghost}
        />
      );
      break;
    default:
      throw new Error('Unknown zone type');
  }

  return component;
};

export const createFormComponent = (
  annotation,
  store,
  ghost = false,
  extraProps = {}
) => {
  const props = {
    annotations: store,
    annotation,
    ghost,
    ...extraProps,
  };
  const { customData } = annotation;
  let component = null;
  switch (true) {
    case customData.type === 'radio':
      component = <Toggle type="radio" {...props} />;
      break;

    case customData.type === 'checkbox':
      component = <Toggle type="checkbox" {...props} />;
      break;

    case customData.type === 'dropdown' ||
      TEXT_RENDER_TYPES.includes(customData.type):
      component = <Text {...props} />;
      break;

    default:
      throw new Error('Unknown tab type');
  }

  return component;
};

export const createPdfAnnotateComponent = (
  annotation,
  store,
  ghost = false
) => {
  const props = {
    annotations: store,
    annotation,
    ghost,
  };

  const { customData } = annotation;
  let component = null;
  switch (true) {
    case customData.type === 'pdf-stamp':
      component = <PdfStamp {...props} />;
      break;

    case customData.type === 'pdf-text':
      component = <PdfText {...props} />;
      break;

    case customData.type === 'pdf-checkbox':
      component = <PdfCheckbox {...props} />;
      break;

    case customData.type === 'pdf-redact-area':
      component = <PdfRedactArea {...props} />;
      break;

    case customData.type === 'pdf-redact-text':
      component = <PdfRedactText {...props} />;
      break;

    case customData.type === 'pdf-strikeout-text':
      component = <PdfStrikeoutText {...props} />;
      break;

    case customData.type === 'pdf-strikeout-line':
      component = <PdfStrikeoutLine {...props} />;
      break;

    default:
      throw new Error('Unknown pdf annotation type');
  }

  return component;
};

export const createSpecialFieldComponent = (
  annotation,
  store,
  ghost = true
) => {
  const type = annotation?.customData?.type ?? false;
  const props = {
    annotations: store,
    annotation,
    ghost,
  };
  let component = null;

  switch (true) {
    case type === 'filestack-image':
      component = <FilestackImage {...props} />;
      break;
    default:
      throw new Error('Unknown special annotation type');
  }

  return component;
};

function calcInputOverlayDimensions(annotations, padding = 2) {
  if (!annotations.length) {
    return {};
  }

  const res = {};
  const { type } = annotations[0].customData || {};
  /*
    Strategy: calculate the left, top, right and bottom most points among
    all fields on the same page.
  */
  const leftMost = annotations
    .map((a) => a.boundingBox.left)
    .sort((a, b) => a - b)[0];
  const topMost = annotations
    .map((a) => a.boundingBox.top)
    .sort((a, b) => a - b)[0];
  const rightMost = annotations
    .map((a) => a.boundingBox.left + a.boundingBox.width)
    .sort((a, b) => b - a)[0];
  const bottomMost = annotations
    .map((a) => a.boundingBox.top + a.boundingBox.height)
    .sort((a, b) => b - a)[0];

  // NB: this is can cause an issue with the difference between what the ruler in jsapp/src/components/reform/pdf-output/pdf-text-area.tsx can fit and what
  // the actual displayed annotations from jsapp/src/components/documents/pspdfkit/annotations/text.js can fit.
  // For example, there may be a 3 line annotation where it looks like the three lines end at exactly the same position but are actually decimals off of each other.
  // The ruler uses the res.width here and applies it as the width for the whole rectangle (all 3 lines of the annotation),
  // whereas the text.js calculations use the width of each single annotation (which could be less than the overall ruler width).
  // Thus something can fit in the ruler in a given line but not fit in the equivalent line of the text.js.

  res.width = rightMost - leftMost + padding * 2;
  res.height = bottomMost - topMost + padding * 2;
  res.x = leftMost - padding;
  res.y = topMost - padding;

  if (TEXT_RENDER_TYPES.includes(type)) {
    // Indentation handling
    const leftFirstLine = annotations[0].boundingBox.left;
    res.indentHanging = false;
    res.partCount = annotations?.length ?? 1;
    if (leftFirstLine >= leftMost) {
      res.textIndent = leftFirstLine - leftMost;
    } else {
      res.textIndent = leftMost - leftFirstLine;
      res.indentHanging = true;
    }
  }

  // Check whether the last line has a hanging indent / ends early
  const lastLine = annotations[annotations.length - 1];
  res.useOverflowCalculation =
    lastLine.boundingBox.right < annotations[0].boundingBox.right;

  return res;
}

export function getPdfOutput(props) {
  const {
    store,
    annotation,
    onClose,
    focusNext,
    getExtraProps,
    ...otherProps
  } = props;
  const boundOutput = store.getBoundOutput(annotation);
  const extraProps = getExtraProps
    ? getExtraProps({
        ...props,
        boundOutput,
      })
    : {};
  return (
    <PdfOutput
      boundOutput={boundOutput}
      annotation={annotation}
      onClose={onClose}
      focusNext={focusNext}
      {...otherProps}
      {...extraProps}
    />
  );
}

export function createOutputWidgetNode(
  store,
  annotation,
  allFieldAnnotations,
  stopFilling,
  focusNext,
  appStore
) {
  const canFill = !store.isReadOnly(annotation);

  if (!canFill) {
    return null;
  }

  const pages = new Set(allFieldAnnotations.map((a) => a.pageIndex));
  const overlays = [];
  pages.forEach((pageIndex) => {
    const fieldAnnotations = allFieldAnnotations.filter(
      (a) => a.pageIndex === pageIndex
    );
    const {
      x,
      y,
      width,
      height,
      textIndent,
      indentHanging,
      partCount,
      useOverflowCalculation,
    } = calcInputOverlayDimensions(fieldAnnotations, 0);

    const overlayItem = new PSPDFKit.CustomOverlayItem({
      id: `${PDF_ANNOTATION_FILL_OVERLAY_ID}_${pageIndex}`,
      node: document.createElement('div'),
      pageIndex,
      position: new PSPDFKit.Geometry.Point({
        x,
        y,
      }),
    });

    store.pspdfkitInstance.setCustomOverlayItem(overlayItem);

    overlayItem.node.style.width = `${width}px`;
    overlayItem.node.style.height = `${height}px`;
    overlayItem.node.className = 'pdf-fill-node';
    if (annotation?.customData?.type === 'radio') {
      overlayItem.node.style.cssText += 'pointer-events: none !important';
    } else {
      overlayItem.node.style.cssText += 'pointer-events: all !important';
    }

    const props = {
      fontSize: annotation?.customData?.fontSize ?? TEXT_FIELD_FONT_SIZE,
      // Deprecated, but some old forms might still be keeping the
      // defaultValue in the annotation. When in doubt get the default
      // from the field object. See pdf-output/identity-pdf-output.js
      // on how it's done
      defaultValue: annotation?.customData?.defaultValue,
    };

    if (TEXT_RENDER_TYPES.includes(annotation.customData.type)) {
      props.textIndent = textIndent;
      props.indentHanging = indentHanging;
      props.partCount = partCount;
      props.useOverflowCalculation = useOverflowCalculation;
      props.allowOverflow = !store.disableToa;
    } else if (annotation.customData.type === 'radio') {
      props.radioProps = fieldAnnotations.map(({ customData, boundingBox }) => {
        return {
          top: boundingBox.top - y,
          left: boundingBox.left - x,
          width: boundingBox.width,
          height: boundingBox.height,
          value: customData.exportValue,
        };
      });
    } else if (annotation.customData.type === 'checkbox') {
      props.exportValue = annotation.customData.exportValue;
    } else if (annotation.customData.type === 'pdf-strikeout-text') {
      props.exportValue = annotation.customData.exportValue;
    }

    const pdfOutput = getPdfOutput({
      store,
      annotation,
      onClose: stopFilling,
      focusNext,
      ...props,
    });

    const showOptionalTooltip =
      Boolean(annotation.customData.optional) &&
      Object.keys(SIGNATURE_TABS).includes(annotation.customData.type);

    try {
      ReactDOM.render(
        <AppProvider store={appStore} router={appStore.router}>
          {showOptionalTooltip && (
            <div className="pdf-fill-node__tooltip">Optional</div>
          )}
          {pdfOutput}
        </AppProvider>,
        overlayItem.node
      );
      overlays.push(overlayItem);
    } catch (err) {
      store.pspdfkitInstance.removeCustomOverlayItem(overlayItem.id);
    }
  });
  return overlays;
}

export const renderConfigurations = {};

/**
 * if `idOrIdsOrAll = true`, clear all render configurations.
 * if it's an array, clear all the configs in the array
 */
export function clearRenderConfigurations(idOrIdsOrAll) {
  const clearAll = idOrIdsOrAll === true;
  const ids = Array.isArray(idOrIdsOrAll) ? idOrIdsOrAll : [idOrIdsOrAll];

  Object.keys(renderConfigurations).forEach((configId) => {
    if (!(clearAll || ids.includes(configId))) {
      return;
    }
    const { node, onDisappear } = renderConfigurations[configId];
    if (onDisappear) {
      onDisappear(node);
    } else {
      ReactDOM.unmountComponentAtNode(node);
    }
    delete renderConfigurations[configId];
  });
}

export function getAnnotationComponent(annotation, store, ghost = false) {
  const type = (annotation.customData || {}).type || '';
  let component = null;
  try {
    if (Object.keys(SIGNATURE_TABS).includes(type)) {
      component = createTabComponent(annotation, store, ghost);
    } else if (
      Object.keys({
        ...ALL_WIDGETS,
        ...TEXT_SPECIAL_FIELDS,
      }).includes(type) &&
      !Object.keys({
        ...PDF_ANNOTATIONS,
      }).includes(type)
    ) {
      component = createFormComponent(annotation, store, ghost);
    } else if (Object.keys(ZONES).includes(type)) {
      component = createZoneComponent(annotation, store, ghost);
    } else if (Object.keys(PDF_ANNOTATIONS).includes(type)) {
      component = createPdfAnnotateComponent(annotation, store, ghost);
    } else if (Object.keys(SPECIAL_FIELDS).includes(type)) {
      component = createSpecialFieldComponent(annotation, store, ghost);
    } else {
      component = createUnknownComponent(annotation, store);
    }
  } catch (err) {
    return null;
  }
  return component;
}

export default function getAnnotationRenderers(store, ui, features) {
  return ({ annotation }, ghost = false) => {
    const renderConfig = renderConfigurations[annotation.id];
    if (!ghost && renderConfig?.component) {
      const { node, component } = renderConfig;
      renderReactComponent(
        <Provider ui={ui} features={features}>
          {component}
        </Provider>,
        node
      );
      return lodashOmit(renderConfigurations[annotation.id], ['component']);
    }

    const node = lodashGet(
      renderConfigurations,
      `${annotation.id}.node`,
      createRootNode(annotation, {
        designMode: store.isDesignMode,
      })
    );

    if (node === null) {
      return null;
    }

    const component = getAnnotationComponent(annotation, store, ghost);

    if (component === null) {
      return {
        node,
        append: false,
      };
    }

    renderReactComponent(
      <Provider ui={ui} features={features}>
        {component}
      </Provider>,
      node,
      true
    );

    // store config
    renderConfigurations[annotation.id] = {
      node,
      append: false,
      component,
      onDisappear: () => {
        ReactDOM.unmountComponentAtNode(node);
      },
    };

    return lodashOmit(renderConfigurations[annotation.id], ['component']);
  };
}
